<template>
    <v-container fluid>
        <v-row class="primary sticky-top">
            <v-col cols="12">
                <v-toolbar flat color="primary" dark>
                    <v-toolbar-title> {{ $t("SmartNavBar.My_quotes") }} </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                        <v-text-field class="my-auto" background-color="transparent" :placeholder="$t('Your_reference')"
                            solo flat v-model="searchValue" append-icon="mdi-magnify" hide-details clearable
                            @keyup.native.enter="onSearch" @click:append="onSearch"
                            @click:clear="onClear"></v-text-field>
                    </v-toolbar-items>
                </v-toolbar>
            </v-col>
            <v-progress-linear v-if="loading" bottom indeterminate striped color="white"></v-progress-linear>
        </v-row>
        <v-row justify="center" v-for="quote of quoteList" :key="quote.offertenr">
            <v-col cols="12">
                <quoteCard :quote="quote" />
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-btn class="my-3" v-if="this.rowIdent" :loading="loading" color="primary" @click="getSalesQuoteList()">
                {{ $t('Load_more') }}
            </v-btn>
        </v-row>
    </v-container>
</template>

<script>
import caas from "@/services/caas";
import QuoteCard from "../components/QuoteCard";
export default {
    name: "SalesQuotes",
    components: { QuoteCard },
    data() {
        return {
            loading: false,
            tabId: 0,
            quoteList: [],
            rowsToBatch: 10,
            rowNum: 0,
            rowIdent: "",
            searchValue: "",
        };
    },
    methods: {
        getSalesQuoteList() {
            this.loading = true;
            let params = {
                statusFilter: this.tabId,
                rowNum: this.rowNum,
                rowIdent: this.rowIdent,
                rowsToBatch: this.rowsToBatch,
                search: this.searchValue,
            };
            return caas
                .rpc("getPortalUserQuotes", params)
                .then((response) => {

                    this.loading = false;
                    if (response.data.success.data) {
                        this.quoteList.push(
                            ...response.data.success.data.quoteView.quote
                        );
                        this.rowNum = response.data.success.rowNum;
                        this.rowIdent = response.data.success.rowIdent;
                    } else if (response.data.success.error) {
                        throw response.data.success.error;
                    }
                })
                .catch((error) => {
                    console.log(error.message);
                    this.loading = false;
                })
        },
        onTabChanged() {
            this.quoteList = [];
            this.rowNum = 0;
            this.rowIdent = "";
            this.getSalesQuoteList();
        },
        onClear() {
            this.searchValue = "";
            this.onSearch();
        },
        onSearch() {
            this.quoteList = [];
            this.rowNum = 0;
            this.rowIdent = "";
            this.getSalesQuoteList();
        },
    },
    mounted() {
        this.getSalesQuoteList();
    },
};
</script>
