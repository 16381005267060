/* eslint-disable */
<style scoped>
/* remove underline from text fields */
.no-underline>>>.v-input__slot::before,
.no-underline>>>.v-input__slot::after {
	border-bottom: none !important;
	border-color: white !important;
}

.no-underline>>>.v-input__slot {
	font-size: 14px !important;
}
</style>
<template>
    <v-card outlined rounded max-width="1080" class="mx-auto">
        <v-card-title>
            <span>{{ $t('SalesQuotes.Sales_quote') }} {{ quote.offertenr }}</span>
            <v-spacer />
            <v-btn plain @click.stop="previewDocument">
                <v-icon>mdi-file-document-outline</v-icon>
                <span>pdf</span>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-row justify="start">
                <v-col cols="3">
                    <v-text-field class="no-underline" :label="$t('Date')" :value="$d(new Date(quote.datum), 'short')"
                        readonly />
                </v-col>
                <v-col cols="6">
                    <v-text-field class="no-underline" :label="$t('Your_reference')" :value="quote.ref" readonly />
                </v-col>
                <v-spacer />
                <v-col cols="3">
                    <v-text-field class="no-underline" :label="$t('SalesQuotes.Valid_thru')"
                        :value="$d(new Date(quote.vervaldatum), 'short')" readonly />
                </v-col>
            </v-row>
            <v-sheet outlined>
                <v-data-table disable-sort disable-pagination hide-default-footer :items="quote.quoteLine"
                    :headers="quoteLineHeaders" dense height="175" fixed-header>
                    <template v-slot:[`item.ehp`]="{ item }">
                        <span>{{ $n(item.ehp, "decimal") }}</span>
                    </template>
                    <template v-slot:[`item.quoteLine`]="{ item }">
                        <span>{{ item.aantal }}</span>
                    </template>
                    <template v-slot:[`item.hkort`]="{ item }">
                        <span v-if="item.hkort">{{
                            $n(item.hkort * 0.01, "percent")
                        }}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <cart-item-toolbar :productCode="item.anr" :minimalOrderQty="item.aantal" />
                    </template>
                </v-data-table>
            </v-sheet>
        </v-card-text>
    </v-card>
</template>

<script>
import caas from "@/services/caas";
import CartItemToolbar from "./CartItemToolbar.vue";
export default {
    name: "QuoteCard",
    components: { CartItemToolbar },
    props: {
        quote: Object,
    },
    data() {
        return {
            quoteLineHeaders: [
                { text: this.$t('Product_code'), value: "anr" },
                {
                    text: this.$t('Product_description'),
                    value: "omschrijving",
                    align: "start",
                    width: "40%",
                },
                { text: this.$t('Quantity'), value: "aantal", align: "end" },
                { text: this.$t('Unit_price'), value: "ehp", align: "end" },
                { text: "", value: "hkort", align: "end" },
                { text: this.$t('Vat_percentage'), value: "btw", align: "end" },
                { value: 'actions', align: 'center' }
            ],
        };
    },
    computed: {},
    methods: {
        upsertCartItem(quoteLine) {
            this.loading = true;
            let cartItemUpd = {
                cartItemId: "",
                productCode: quoteLine.anr.trim(),
                quantity: Number(quoteLine.aantal)
            };

            this.$store
                .dispatch("shoppingCart/upsertCartItem", cartItemUpd)
                .then(() => {
                    //this.setDataModified(false);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false
                })
        },
        previewDocument() {
            let params = {
                documentId: this.quote.offertenr,
                documentType: "OF",
            };
            caas.rpc("getDocumentB64", params).then((response) => {
                // Convert base64 string to binary data
                const binaryData = this.base64ToBinary(response.data.success.documentB64);

                // Create a blob from the binary data
                const blob = new Blob([binaryData], { type: 'application/pdf' });

                // Create an object URL from the blob
                const url = URL.createObjectURL(blob);

                // Open the object URL in a new tab
                window.open(url, '_blank');
            });
        },

        base64ToBinary(base64) {
            const raw = window.atob(base64);
            const rawLength = raw.length;
            const array = new Uint8Array(new ArrayBuffer(rawLength));

            for (let i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            return array;
        }
    },
};
</script>
